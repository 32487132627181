import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import Seo from "../components/common/seo"
import Layout from "../components/common/layout"
import Modal from "react-modal"
import Hero from "../components/members-zone/hero.js"
import mzNav1 from "../images/mzNav/1.png"
import mzNav2 from "../images/mzNav/2.png"
import mzNav3 from "../images/mzNav/3.png"
import mzNav4 from "../images/mzNav/4.png"
import mzNav5 from "../images/mzNav/5.png"

import mzInstaller1 from "../images/mzInstaller/1.png"
import mzInstaller2 from "../images/mzInstaller/2.png"
import mzInstaller3 from "../images/mzInstaller/3.png"
import mzInstaller4 from "../images/mzInstaller/4.png"
import mzInstaller5 from "../images/mzInstaller/5.png"
import mzInstaller6 from "../images/mzInstaller/6.png"
import mzInstaller7 from "../images/mzInstaller/7.png"
import mzInstaller8 from "../images/mzInstaller/8.png"


const MembersZone = ({ data }) => {

  const [modalIsOpen, setIsOpen] = useState(false)
  function openModal() {
    setIsOpen(true)
  }
  function closeModal() {
    setIsOpen(false)
  }
    
 const [modalIsOpenTwo, setIsOpenTwo] = useState(false)
  function openModalTwo() {
    setIsOpenTwo(true)
  }
  function closeModalTwo() {
    setIsOpenTwo(false)
  }
  
  const [modalIsOpenThree, setIsOpenThree] = useState(false)
  function openModalThree() {
    setIsOpenThree(true)
  }
  function closeModalThree() {
    setIsOpenThree(false)
  }
    
const [modalIsOpenFour, setIsOpenFour] = useState(false)
  function openModalFour() {
    setIsOpenFour(true)
  }
  function closeModalFour() {
    setIsOpenFour(false)
  }

const [modalIsOpenFive, setIsOpenFive] = useState(false)
  function openModalFive() {
    setIsOpenFive(true)
  }
  function closeModalFive() {
    setIsOpenFive(false)
  }
    
const [modalIsOpenSix, setIsOpenSix] = useState(false)
  function openModalSix() {
    setIsOpenSix(true)
  }
  function closeModalSix() {
    setIsOpenSix(false)
  }
    
const [modalIsOpenSeven, setIsOpenSeven] = useState(false)
  function openModalSeven() {
    setIsOpenSeven(true)
  }
  function closeModalSeven() {
    setIsOpenSeven(false)
  }
  
const [modalIsOpenEight, setIsOpenEight] = useState(false)
  function openModalEight() {
    setIsOpenEight(true)
  }
  function closeModalEight() {
    setIsOpenEight(false)
  }    

const [modalIsOpenNine, setIsOpenNine] = useState(false)
  function openModalNine() {
    setIsOpenNine(true)
  }
  function closeModalNine() {
    setIsOpenNine(false)
  }  
  
  const [modalIsOpenTen, setIsOpenTen] = useState(false)
  function openModalTen() {
    setIsOpenTen(true)
  }
  function closeModalTen() {
    setIsOpenTen(false)
  }  
  
  const [modalIsOpenEleven, setIsOpenEleven] = useState(false)
  function openModalEleven() {
    setIsOpenEleven(true)
  }
  function closeModalEleven() {
    setIsOpenEleven(false)
  }  
  
  const [modalIsOpenTwelve, setIsOpenTwelve] = useState(false)
  function openModalTwelve() {
    setIsOpenTwelve(true)
  }
  function closeModalTwelve() {
    setIsOpenTwelve(false)
  }   
  
 const [modalIsOpenThirteen, setIsOpenThirteen] = useState(false)
  function openModalThirteen() {
    setIsOpenThirteen(true)
  }
  function closeModalThirteen() {
    setIsOpenThirteen(false)
  } 
  
const [modalIsOpenFourteen, setIsOpenFourteen] = useState(false)
  function openModalFourteen() {
    setIsOpenFourteen(true)
  }
  function closeModalFourteen() {
    setIsOpenFourteen(false)
} 

const [modalIsOpenFifteen, setIsOpenFifteen] = useState(false)
  function openModalFifteen() {
    setIsOpenFifteen(true)
  }
  function closeModalFifteen() {
    setIsOpenFifteen(false)
    } 
    
const [modalIsOpenSixteen, setIsOpenSixteen] = useState(false)
  function openModalSixteen() {
    setIsOpenSixteen(true)
  }
  function closeModalSixteen() {
    setIsOpenSixteen(false)
} 

const [modalIsOpenSeventeen, setIsOpenSeventeen] = useState(false)
  function openModalSeventeen() {
    setIsOpenSeventeen(true)
  }
  function closeModalSeventeen() {
    setIsOpenSeventeen(false)
} 

    
  Modal.setAppElement("#___gatsby")

          useEffect(() => {
      if (window.location.href.indexOf("fr") > -1) {
        setTimeout(() => {
        document.querySelector("body").classList.add("fr");
      }, 500)
        // alert("hello")
      }
          }, [])
  
    return (
        <div className="membersZone resources">
            <Layout>
                <Seo title="Members Zone" />
                <Hero />
                <div className="membersZoneWrapper en-only">
                    <h3 onClick={openModalFive}>Navigator R/F</h3>
                    <Modal
                        isOpen={modalIsOpenFive}
                        onRequestClose={closeModalFive}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                        <h2>
                            Navigator R/F
                        </h2>
                        <h3><a onClick={openModalFive}>Installing COMMB DataSuite</a></h3>
                        <h3>How to Use the Software:</h3>
                        <ul>
                            <li><a target="_blank" href="https://www.youtube.com/watch?v=7pbWQpaW72U">Training Video</a></li>
                            <li>Tutorials (segmented into bite-sized topics)</li>
                            <Modal
                                isOpen={modalIsOpenThirteen}
                                onRequestClose={closeModalThirteen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>COMMBNavigator®</h2>
                                <p>These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                                <hr />
                                <br />
                                <h3>Video 1</h3>
                                <p>Understanding the basics; how to get around</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/_mYqMDcvxHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 2</h3>
                                <p>Using the Plan Builder to construct simple plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/PucnA7w9qFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 3</h3>
                                <p>Using the Plan Builder to compare multi-scenario plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cWWss2x5FyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 4</h3>
                                <p>How to select a basic target & create a combined target</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8T_kV2ygi8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 5</h3>
                                <p>How to build and save your own report layouts</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 6</h3>
                                <p>How to review and finalize report details</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 7</h3>
                                <p>Saving options</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 8</h3>
                                <p>How to use the Flighting option</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2tcwsDNbt6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 9</h3>
                                <p>How to build graphs</p>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rEjcqs7KQ_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <a className="closeBtnModal" onClick={closeModalThirteen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                            <ul>
                                <li><a onClick={openModalThirteen}>Video</a></li>
                                <li><a onClick={openModalFifteen}>Print</a></li>
                            </ul>
                            <li><a>Quick Tips Series (learn how to get the most out of the software)</a></li>
                            <li><a onClick={openModalSeventeen}>FAQ</a></li>
                            <Modal
                                isOpen={modalIsOpenSeventeen}
                                onRequestClose={closeModalSeventeen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>FAQ - COMMBNavigator®</h2>
                                <h3>1. How can I access the COMMBNavigator® software?</h3>
                                <p>Once you become a COMMB member you can obtain your username/password by filling out the online request form. Please contact your IT department to handle the installation of the software since administrative permissions are required for this.</p>
                                <hr />
                                 <br />
                                <h3>2. How can I understand some of the terms/data variables used in COMMBNavigator®?</h3>
                                <p>Once you are logged into the COMMBNavigator® you can access the “Definitions” document in the Help menu.</p>
                                <hr />
                                 <br />
                                <h3>3. How often is COMMBNavigator® updated?</h3>
                                <p>Twice a year (January and July). An off-schedule update is sometimes requested by an OOH company member when a significant inventory change has occurred that they would like to see reflected immediately.</p>
                                <hr />
                                 <br />
                                <h3>4. Why are some of COMMB’s Outdoor members not included in COMMBNavigator®?</h3>
                                <p>R/F estimates are determined through the amalgamation of OOH inventory data and Numeris-RTS target data. As a result, COMMB Outdoor members are required to have a Numeris membership and be a subscriber to the NLogic-RTS Consumer Study to be reported in COMMBNavigator®.</p>
                                <p>COMMB’s Agency/Advertiser members must also be members of Numeris to gain access to the software.</p>
                                <hr />
                                 <br />
                                <h3>5. Are Outdoor digital products reported in COMMBNavigator®?</h3>
                                <p>Yes they are. Please note that current reach/frequency calculations use circulations that are based on per face, not per advertising spot.</p>
                                <hr />
                                 <br />
                                <h3>6. How many markets does COMMBNavigator® report on?</h3>
                                <p>45 including Toronto, Montreal, Vancouver, Calgary, Edmonton, Ottawa, London, Quebec City, Halifax, Winnipeg and Regina.</p>
                                <hr />
                                 <br />
                                <h3>7. Is COMMBNavigator® included in COMMB’s membership fees?</h3>
                                <p>Yes, there are no extra charges for access to COMMBNavigator®unless you are not a member of Numeris/RTS.</p>
                                <hr />
                                 <br />
                                <h3>8. Are there any license fees for COMMBNavigator®or the COMMB Data Report software?</h3>
                                <p>No. There are no fees to obtain multiple licenses for our current software so all users in the same company can have their own accounts at no extra charge.</p>
                                <hr />
                                 <br />
                                <h3>9. Why is there no rate card in COMMBNavigator®?</h3>
                                <p>Significant variances can occur with rates based on a number of industry factors that take place over a year so we do not want to misinform our clients by using a rate card. Please contact the relevant OOH company to obtain costs.</p>
                                <hr />
                                 <br />
                                <h3>10. Can I use COMMBNavigator® (or the COMMB Data Report) on my MAC?</h3>
                                <p>The software is not MAC-compatible however, there are a couple of work-around options you can discuss with your IT department:</p>
                                <p>Apple provides Boot Camp, a means of installing Windows on Macs in dual-boot fashion.</p>
                                <p>The more seamless route is to use virtual machine software, like Parallels, which effectively runs Windows (or a single Windows application) within OS X.</p>
                                <hr />
                                 <br />
                                <h3>11. Why are Transit shelters reported under the Street Furniture product and not broken out?</h3>
                                <p>Products in COMMBNavigator® are grouped together if they are the same size so, for example, all products under Street Furniture (e.g. Transit Shelters, Street Ads, Mediacolumns, Street Posters) that are 6’x 4’ are grouped together under one product name.</p>
                                <a className="closeBtnModal" onClick={closeModalSeventeen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                        </ul>
                        <h3>Resource Documents</h3>
                        <ul>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5vKOqENTHLFHhFQ4TNadQM/43b7651fc5db6ffd7dddce4225dfeb90/COMMBNavigator_Definitions.pdf">Definitions</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/468TEr45YOV6FO4Vpap1Kz/a4199cb1bb6cccbe207dd1a5c6c3dd62/Technical_Details_COMMB_Navigator.pdf">COMMBNavigator® Technical Details</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/50NouAv74wkFEvINfIawcz/ca7fb742bbb57ae756b1a733f9ee9fe4/RTS_Technical_Details_Fall_2021.pdf">RTS Technical Details – Fall 2021</a></li>
                            <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/CAKnv2CNSfxMMS1yaRG44/3ef285f45f5a4eb285c7cce7b6fcc99f/2021_Spring_RTS-Codebook-External-English.pdf">RTS Codebook – Fall 2021</a></li>
                        </ul>
                        <h3>Support Inquiries</h3>
                        <ul>
                            <li>Technical & General: <a target="_blank" href="mailto:support@commb.ca">support@commb.ca</a></li>
                            <li>Installation & Access: <a target="_blank" href="mailto:install@commb.wpengine.com">install@commb.wpengine.com</a></li>
                        </ul>
                        <h3>Training</h3>
                        <p>Demonstration Webinar: <a target="_blank" href="https://commb.wpengine.com/mz-commb-school/">COMMB School Registration</a></p>
                        <p>Custom Training: <a target="_blank" href="mailto:jstick@commb.wpengine.com">Email Jeremy Stick</a></p>
                        <a className="closeBtnModal" onClick={closeModalFive}>
                            {" "}
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="17.688"
                                height="17.688"
                                viewBox="0 0 17.688 17.688"
                            >
                                <path
                                id="close-icon"
                                d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                transform="translate(-7.5 -7.5)"
                                fill="#fff"
                                ></path>
                            </svg>
                        </a>
                    </Modal>
                        <ul>
                            <li><a target="_blank" href="https://www.youtube.com/watch?v=7pbWQpaW72U">Training Video</a></li>
                            <li><a onClick={openModalFourteen}>Video Tutorials</a></li>
                            <Modal
                                isOpen={modalIsOpenFourteen}
                                onRequestClose={closeModalFourteen}
                                bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                                <h2>COMMBNavigator®</h2>
                                <p>These videos are best viewed at 720p resolution and full screen. Place your cursor at the bottom of the video screen to access these menu selections.</p>
                                <hr />
                                <br />
                                <h3>Video 1</h3>
                                <p>Understanding the basics; how to get around</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/_mYqMDcvxHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 2</h3>
                                <p>Using the Plan Builder to construct simple plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/PucnA7w9qFo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 3</h3>
                                <p>Using the Plan Builder to compare multi-scenario plans</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/cWWss2x5FyQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 4</h3>
                                <p>How to select a basic target & create a combined target</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/8T_kV2ygi8o" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 5</h3>
                                <p>How to build and save your own report layouts</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/KUW37B0zmRs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 6</h3>
                                <p>How to review and finalize report details</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/HUamx1CwfvM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 7</h3>
                                <p>Saving options</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2nAiXbb9nzk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 8</h3>
                                <p>How to use the Flighting option</p>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2tcwsDNbt6g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <h3>Video 9</h3>
                                <p>How to build graphs</p>
                                    <iframe width="560" height="315" src="https://www.youtube.com/embed/rEjcqs7KQ_A" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                                <a className="closeBtnModal" onClick={closeModalFourteen}>
                                    {" "}
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="17.688"
                                        height="17.688"
                                        viewBox="0 0 17.688 17.688"
                                    >
                                        <path
                                        id="close-icon"
                                        d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                        transform="translate(-7.5 -7.5)"
                                        fill="#fff"
                                        ></path>
                                    </svg>
                                </a>
                            </Modal>
                            <li><a onClick={openModalFifteen}>Print Tutorials</a></li>
                            <Modal
                            isOpen={modalIsOpenFifteen}
                            onRequestClose={closeModalFifteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                            >
                            <h2>Print Tutorials</h2>   
                            <h3>1. Choosing Inventory</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4LsiurWZ8uFm84CQf5m6fr/6a90e0da925254e2e1d558f2faed7823/um1_1.pdf">1.1 Finding which OOH Companies have a particular product within one or more markets</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7zaqNhMfvE2oV2dbNXxpNU/cc3b1d8098e4a6a296a0ae8b039410db/um1_2.pdf">1.2 Determining inventory to use in a plan</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2oWmPA7QlbPRAep0NLtdqv/67ffffc379f84388df4822fee9b62845/um1_3.pdf">1.3 Adding inventory to a new plan</a></li>
                            </ul>
                            <h3>2. Defining Plans</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5BJuRLTzkTZZNoFUWBUwfr/107c9f9c029e5966f40f7c6809f9f8d6/um2_1.pdf">2.1 Assigning the same single value to the number of weeks column for all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3DMwhCKxYaRKh4r1vnsrXf/a85e3b690408c4911eef16db43fa8fe8/um2_2.pdf">2.2 Assigning the same costing to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/6jHPcUbJdTYWrr8EyQXjXb/47d2844c63090c86f3d0efc65ac1eb1b/um2_3.pdf">2.3 Assigning the same objective to all Market – OOH Company – Product combinations</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1G9QtOfFhoiPKsKho5yV4t/92d3a217c8a6bdf3a225415ddd752a7f/um2_4.pdf">2.4 Defining a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/SAwFRTnKbPwJmcaqpFnds/e9e69b48174ea10f01bd45877e9abb03/um2_5.pdf">2.5 Defining a plan with different campaign lengths for some products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5EXyED4ITCM3umQZ8UqAJI/fe014e208540a15cac2dcc119c281989/um2_6.pdf">2.6 Defining a plan to compare different objective levels for each product</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2IoEi0YOLqBQHI2raJkV4T/3a65c8b83c62b91123f91bcf96ac496a/um2_7.pdf">2.7 Applying costs to a plan with the same campaign lengths for all products</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2dsp9gJ9j8AJVfWQu2O2H4/e94f0caf97ee8125336ffbd0833d0154/um2_8.pdf">2.8 Overriding a circulation</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7szCC2r8PkwkHJfIPrSWkQ/053fa5e8436f51e7233b680e48b612bb/um2_9.pdf">2.9 Defining multiple plans to compare products</a></li>
                            </ul>   
                            <h3>3. Flighting</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3QsYTc0uouotbzsJ7RlcgQ/97137088e68f06f9ea17312eaa14c92d/um3_1.pdf">3.1 Creating a Flighting schedule for all selected inventory</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1IJiMjLLA31Y2AQgGmdtBs/8c4d12981863855714dc5511a5c3607e/um3_2.pdf">3.2 Creating different Flighting schedules that vary by product</a></li>
                            </ul>
                            <h3>4. Targets</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2SF6c6xYpLob1teL8jUI11/860e5fc6478d21b0f80b9c352af38a94/um4_1.pdf">4.1 Creating a new combined target</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3yeyWriGYn55qwMWOlp54o/62dc748aa52de8b305f8c66c023fa56e/um4_2.pdf">4.2 Adding a single target</a></li>
                            </ul>
                            <h3>5. Report Appearance</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/sJskb0nmp2vqSu5C9ALcT/a9732b254ccab71e10add4ce80dee5a4/um5_1.pdf">5.1 Enter or change the report title and footer</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5XVVG3eHDzh1AFm9YFzXjD/f3e7bf83d9b0c020ead59be4f20037bd/um5_2.pdf">5.2 Changing preferences</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4T8tnRdBE6I4IpcA2wHCGS/667ab62fa4400e97e28cf4e8b7394ead/um5_3.pdf">5.3 Adding a logo to reports</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2l8xCKni2t4SIC2hsGzvKb/3529f5caf7e6194c327bc36dc06e41a1/um5_4.pdf">5.4 Altering the columns of a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1WeVwAcN2F7lTlwuX7dkVW/f41f08e168429f9990da899d86816441/um5_5.pdf">5.5 Changing the report group order for a single-level or mutli-level report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4mRObMKzv5RCHfy3bC8Az3/08a9142c606b458555504387753cdff3/um5_6.pdf">5.6 Changing the order of markets in a report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/5S8txUFBr7STOtseeeUhQP/dcd4a0f1b0119048438689a3cdfeb404/um5_7.pdf">5.7 Removing mixes from a report</a></li>
                            </ul>
                            <h3>6. Managing Files</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58Pjncd5t85Be0u0Dc8sLV/4ada7304f3255b8348013ad83a34ad03/um6_1.pdf">6.1 Saving data selections</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4W431f6LEyJL2MvTyRrHUA/011f64136952177fe75c7cf65c023da3/um6_2.pdf">6.2 Opening files</a></li>
                            </ul>
                            <h3>7. Viewing and Exporting Reports</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/7G14cTtYfzgJEC9lOYWLhk/d7a958f9f43827dc970d7b7db80e125c/um7_1.pdf">7.1 Completing the report</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3FqLvgDMRSBgN1IqbWvQKs/ddcb904206722b4f632bf98c2770ec2c/um7_2.pdf">7.2 Recalculating results</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/ru9WDVCTY2ibaso6SWWtg/9befeac5c68a4e0191de924504bad0af/um7_3.pdf">7.3 Saving another copy of the report under a different filename in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1nVtBGmFGFWl0LueV7iy1V/3e65355fda1c423f6724b2728d59d8cf/um7_4.pdf">7.4 Saving another copy of the report under a different filename in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/58blpQ176UJThxRZ4tcBgg/4644e65cd10b26f239c0fca583d7f67d/um7_5.pdf">7.5 Saving a copy of the report to PDF format in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/4WSZEwm7z3OcQT5NMqteVe/2998d3d712af05b0f5543b1625cde50a/um7_6.pdf">7.6 Saving a copy of the report to PDF format in Excel 2013</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/1xu2UdUNhyTUQ2rjlYXkyY/4cf528de36b1476b78447eb324548482/um7_7.pdf">7.7 Sending a PDF copy of the report via email in Excel 2010</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/2Oko70qTEGSWcG7QIkY0Kx/ca0edcbf39e9c087c15439877f13d370/um7_8.pdf">7.8 Sending a PDF copy of the report via email in Excel 2013</a></li>
                            </ul>
                            <h3>8. Graphing</h3>
                            <ul>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/zES9upqsJfOByXtJXy1GM/4f6692aeef2cfe6ddc23b5b88603bf78/um8_1.pdf">8.1 Comparing Reach Build</a></li>
                                <li><a target="_blank" href="https://assets.ctfassets.net/1l6si2vnlb2k/3XOfnCTzns805Cn3AJQlWf/c4b5250f0f505d82b3c9a01322c045a2/um8_2.pdf">8.2 Graphing Effective Reach</a></li>
                            </ul>
                            <a className="closeBtnModal" onClick={closeModalFifteen}>
                                {" "}
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="17.688"
                                    height="17.688"
                                    viewBox="0 0 17.688 17.688"
                                >
                                    <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                    ></path>
                                </svg>
                            </a>
                            </Modal>
                            <li><a onClick={openModalSixteen}>Quick Tips Series</a></li>
                            <Modal
                            isOpen={modalIsOpenSixteen}
                            onRequestClose={closeModalSixteen}
                            bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Quick Tips Series</h2>
                            <ul>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/1fBvJ6C9RtzuRydNpPeH8k/336287df60533b2a90e2afac894f2e0f/Quick_Tip_1_Become_an_Expert_Navigator.pdf" target="_blank">Quick Tip #1 - Become an Expert Navigator</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/72VVHRfeOxaLCQbwJWdgll/5f878f58a7ebe2ebe693714d8fee5a91/Quick_Tip_2_Plan_smarter._Faster..pdf" target="_blank">Quick Tip #2 - Plan Smarter. Faster.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/22YcNGUs3dD8DSV7ie5DmN/10004395b90db9351f7d9fef7b2dbf84/Quick_Tip_3_Simple_steps_for_planning_smarter.pdf" target="_blank">Quick Tip #3 - Simple steps for planning smarter</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/4lel6EhnwUGvvWfNDA3gXY/921c09d7c2ce09951605cbd19da87bf3/Quick_Tip_4_A_step_in_the_right_direction.pdf" target="_blank">Quick Tip #4 - A step in the right direction</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/37omXPtNtpkSaBYUNp1WOS/630261d392232eca27bebc5a923eff4e/Quick_Tip_5_Getting_the_Most_out_of_Plan_Builder.pdf" target="_blank">Quick Tip #5 - Getting the most out of Plan Builder</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/BtkdEE0S6vuSqNryTLSPN/47403806b16b39b9bec14c8e2f71ace0/Quick_Tip_6_Your_Reports._Your_Way..pdf" target="_blank">Quick Tip #6 - Your Reports. Your Way.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/6XdfAzs13XbTM9WwEIu8pU/c53b3437d5439598d6b1b8edd572557a/Quick_Tip_7_Flighting_Made_Easy.pdf" target="_blank">Quick Tip #7 - Flighting Made Easy</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/yymTMmLgve2hXrZBnt7ps/a51c86a17bbeae4ad92fdffb4cc58bad/Quick_Tip_8_Graph_Your_Results.pdf" target="_blank">Quick Tip #8 - Graph Your Results</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/35eL1dbrzvVA52s0WjKRLM/2b56a3ac408d24b7ebbfc6b57b8f3b2b/Quick_Tip_9_Save_It._Your_Way..pdf" target="_blank">Quick Tip #9 - Save It. Your Way.</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/69vZZlTV6WJpkQRCkRAb4N/6c9dea094bcac091d38902b316613b58/Quick_Tip_12_Sharing_a_Plan_with_a_colleague.pdf" target="_blank">Quick Tip #10 - Circulation Overwrite Feature</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/4AhG4NLUWtGApgaHOKnW7r/c79ba11dd128d69153d09e1d63dcb175/Quick_Tip_11_Navigator_Change_Wave_Feature.pdf" target="_blank">Quick Tip #11 - Change Wave Feature</a></li>
                                <li><a href="https://assets.ctfassets.net/1l6si2vnlb2k/69vZZlTV6WJpkQRCkRAb4N/6c9dea094bcac091d38902b316613b58/Quick_Tip_12_Sharing_a_Plan_with_a_colleague.pdf" target="_blank">Quick Tip #12 - Sharing a Plan With a Colleague</a></li>
                            </ul>
                        </Modal>
                        </ul>                
                </div>
                <div className="membersZoneWrapper fr-only">
                    <h2>Ressources</h2>
                    <h3>Installation du logiciel</h3>
                    <p>Pour obtenir les instructions d’installation : <a onClick={openModal}>Installer les logiciels COMMB</a></p>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2>Installer les logiciels COMMB</h2>
                      <p>Nous vous recommendons de faire appel à votre service informatique puisque les utilisateurs doivent posséder les droits d’administration. Les détails pour l’installation du logiciel sont indiqués dans la section Didacticiels ci-dessous.</p>
                      <h3><a href="http://www.rja.net/comb/COMMBDataSuiteSetup.msi">COMMBDataSuiteSetup.msi (9 MB)</a></h3>
                      <p>(COMMBDataSuiteSetup.msi est l’installateur pour les logiciels NavigateurCOMMBMD et le Rapport de données COMMB).</p>
                      <h3>Didacticiels</h3>
                      <ul>
                        <li><a onClick={openModalSix}>Comment installer le NavigateurCOMMBMD et le Rapport de données COMMB</a></li>
                        <Modal
                        isOpen={modalIsOpenSix}
                        onRequestClose={closeModalSix}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                            <h2>Comment installer les logiciels</h2>
                            <p>Ce guide vous indique de manière détaillée comment télécharger, installer et exécuter le gestionnaire de la suite logicielle COMMB (l’installateur du NavigateurCOMMBMD et du Rapport de données COMMB).</p>
                            <p>Pour les installations de groupes, veuillez consulter les indications concernant le déploiement en réseau du NavigateurCOMMBMD et du Rapport de données COMMB.</p>
                            <hr />
                            <br />
                            <h3>Téléchargez l’installateur COMMBDataSuiteSetup</h3>
                            <img src={mzInstaller1} />
                            <ul>
                              <li>1. Cliquez ici pour télécharger le fichier COMMBDataSuiteSetup.msi.</li>
                              <li>2. Selon les paramètres de votre fureteur, vous aurez le choix A) d’ouvrir et d’exécuter le fichier ou B) de l’enregistrer dans votre ordinateur.</li>
                              <li>3. Si vous enregistrez COMMBDataSuiteSetup dans votre ordinateur, notez le dossier où vous le faites. Nous vous recommandons de l’enregistrer sur le bureau ou dans votre dossier de téléchargements qui sont faciles à retrouver. Naviguez vers ce dossier et exécutez l’installateur en double-cliquant sur lui.</li>
                            </ul>
                            <p><strong>Remarque:</strong> Si vous utilisez un antivirus comme Norton Antivirus, celui-ci pourrait vous demander si vous faites confiance à ce logiciel ou à ce site Web. Répondez « Oui » ou « OK » pour poursuivre.</p>
                            <h3>Confirmez que vous souhaitez exécuter COMMBDataSuiteSetup</h3>
                            <img src={mzInstaller2} />
                            <p>Dans les versions XP SP2, Vista et 7 de Windows, le système d’exploitation vous demandera habituellement si vous voulez poursuivre l’exécution de COMMBDataSuiteSetup, en vous présentant l’une des fenêtres de dialogue ci-haut.</p>
                            <p>Vous remarquerez que la boîte de dialogue indique Richard Jean & Associates Inc. à la ligne « Éditeur ». Pour votre information, il s’agit de l’entreprise ayant développé le NavigateurCOMMBMD et le Rapport de données COMMB.</p>
                            <p>L’installation du gestionnaire des logiciels COMMB exige que vous soyez administrateur de l’ordinateur. Si votre compte Windows est configuré comme administrateur, entrez simplement votre mot de passe Windows lorsque le système vous le demandera, puis cliquez sur « Oui » pour continuer.</p>
                            <p>Par contre, si vous ne disposez pas des autorisations requises, vous verrez apparaître le dialogue de gauche ou un message similaire. <strong>L’administrateur de votre réseau</strong> devra alors fournir son mot de passe pour vous permettre de poursuivre l’installation. Si cela est impossible, téléchargez plutôt le fichier <a href="http://www.rja.net/comb/COMBDataSuiteSetup.exe">COMMBDataSuiteSetup.exe</a>, qui vous permettra d’installer les logiciels sans être administrateur de votre ordinateur.</p>
                            <hr />
                            <br />
                            <h3>Page d’accueil de l’installateur COMMBDataSuiteSetup</h3>
                            <img src={mzInstaller3} />
                            <p>Lorsque l’assistant d’installation apparaîtra à l’écran, suivez les indications en fermant tous les programmes ouverts, puis cliquez sur « Suivant ».</p>
                            <hr />
                            <br />
                            <h3>Lisez et acceptez l’entente d’utilisation</h3>
                            <img src={mzInstaller4} />
                            <p>Lorsque la page d’entente d’utilisation s’affichera, lisez-la en entier puis cliquez dans la case « J’accepte », si vous en acceptez les conditions. Cliquez sur « Suivant ».</p>
                            <hr />
                            <br />
                            <h3>Terminez l’installation</h3>
                            <img src={mzInstaller5} />
                            <p>Lorsque cet écran apparaît, l’installation est complète. Cliquez sur « Fin. »</p>
                            <hr />
                            <br />
                            <h3>Première exécution du gestionnaire des logiciels COMMB – Lancement</h3>
                            <img src={mzInstaller6} />
                            <p>Trouvez l’icône COMMB dans le menu de démarrage ou sur le bureau de votre ordinateur. Dans le menu de démarrage, sélectionnez l’icône, ou sur le bureau, double-cliquez sur celui-ci. Ceci ouvrira la fenêtre de dialogue suivante.</p>
                            <h3>Remarque : Pour Les Installateurs / Utilisateurs du Rapport de Données COMMB</h3>
                            <img src={mzInstaller7} />
                            <p>La première fois que vous exécuterez le gestionnaire de la suite logicielle COMMB, le programme en téléchargera et installera automatiquement la plus récente version. Pour en savoir plus à ce propos, consultez le tutoriel sur la mise à jour du gestionnaire de la suite logicielle COMMB.</p>                                                                                    
                            <hr />
                            <br />
                            <h3>Connexion au NavigateurCOMMBMD ou au Rapport de données COMMB</h3>
                            <img src={mzInstaller8} />
                            <p>La fenêtre de connexion apparaîtra dès que vous aurez la dernière version du gestionnaire des logiciels COMMB.</p>
                            <p>Vous trouverez votre nom d’utilisateur et votre mot de passe dans le courriel de bienvenue aux logiciels COMMB envoyé à votre adresse courriel. Si vous n’avez pas reçu ce courriel, veuillez communiquer avec <a href="mailto:support@commb.wpengine.com">support@commb.wpengine.com</a>, afin de créer votre compte.</p>
                            <ul>
                              <li>1. Entrez votre nom d’utilisateur du NavigateurCOMMBMD et du Rapport de données COMMB. Il s’agit de votre <strong>adresse courriel.</strong></li>
                              <li>2. Entrez votre mot de passe du NavigateurCOMMBMD et du Rapport de données COMMB dans la case appropriée.</li>
                              <li>3. Si vous préférez ne pas avoir à vous souvenir de votre mot de passe, cliquez dans la boîte <strong>Enregistrer le mot de passe.</strong> Évitez cependant de l’enregistrer si votre ordinateur possède plusieurs utilisateurs.</li>
                              <li>4. Cliquez sur <strong>OK</strong> pour ouvrir votre session du NavigateurCOMMBMD** et/ou du Rapport de données COMMB.</li>
                            </ul>
                            <p>
                              **Seulement certains membres de COMMB propriétaires d’entreprises d’affichage peuvent accéder au NavigateurCOMMBMD.
                            </p>
                            <p>
                              Le NavigateurCOMMBMD ou le Rapport de données COMMB tentera d’ouvrir une session sur le serveur de COMMB. Si cette opération échoue, il est possible que votre connexion Internet soit défectueuse ou que les paramètres du réseau de votre entreprise ne vous permettent pas d’établir ce lien. Le cas échéant, communiquez avec <strong>l’administrateur de votre réseau.</strong>
                            </p>
                            <hr />
                            <br />
                            <p><strong>Remarque:</strong> Il est essentiel au bon fonctionnement du NavigateurCOMMBMD et du Rapport de données COMMB que cette connexion s’effectue au moyen <strong>de vos identifiants privés.</strong> Veuillez donc <strong>éviter de les partager avec qui que ce soit.</strong> Les deux logiciels enregistrent vos fichiers et paramètres personnels dans votre compte en ligne <strong>– quiconque connaît votre mot de passe peut y accéder.</strong></p>
                            <p>Si vous oubliez ou perdez votre mot de passe, entrez votre adresse courriel et cliquez sur le bouton <strong> « Mot de passe oublié ».</strong> Le système vous en créera un nouveau et l’enverra à l’adresse courriel associée à votre compte. Trouvez votre nouveau mot de passe et entrez-le dans la case appropriée de la fenêtre de connexion.</p>
                            <a className="closeBtnModal" onClick={closeModalSix}>
                                {" "}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="17.688"
                                  height="17.688"
                                  viewBox="0 0 17.688 17.688"
                                >
                                  <path
                                    id="close-icon"
                                    d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                                    transform="translate(-7.5 -7.5)"
                                    fill="#fff"
                                  ></path>
                                </svg>
                              </a>
                        </Modal>
                        <li><a onClick={openModalSeven}>Comment mettre à jour le NavigateurCOMMBMD et le Rapport de donées COMMB</a></li>
                        <Modal
                        isOpen={modalIsOpenSeven}
                        onRequestClose={closeModalSeven}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                        >
                          <h2>Comment mettre à jour le gestionnaire</h2>
                          <p>Voici comment mettre à jour le gestionnaire de la suite logicielle COMMB (l’installateur du NavigateurCOMMBMD et du Rapport de données COMMB).</p>
                          <hr />
                          <br />
                          <h4>Lancez le gestionnaire des logiciels COMMB</h4>
                          <img src={mzNav1} />
                          <p>
                            Dans le menu de démarrage, sélectionnez l’icône, ou sur le bureau, double-cliquez sur celui-lui.
                          </p>
                          <hr />
                          <br />
                          <h4>Mises à jour automatiques</h4>
                          <img src={mzNav2} />
                          <p>Avant de démarrer, le gestionnaire des logiciels COMMB vérifie si une mise à jour est disponible. Si c’est le cas, la fenêtre ci-haut apparaît. Cliquez simplement sur <strong>« OK ».</strong></p>
                          <hr />
                          <br />
                          <h4>Téléchargement de la mise à jour</h4>
                          <img src={mzNav3} />
                          <p>La fenêtre vous informera du déroulement du téléchargement. Dès l’opération terminée, le logiciel installera automatiquement la mise à jour.</p>                          
                          <hr />
                          <br />
                          
                          <h4>Téléchargement terminée</h4>
                          <img src={mzNav4} />
                          <p>Le programme s’éteindra, la nouvelle version sera lancée automatiquement et la fenêtre de connexion apparaîtra.</p>   
                          <p><strong>Remarque:</strong> Si le processus de mise à jour se répète en boucle, cela signifie qu’une erreur est survenue lors de l’opération. Dans ce cas, veuillez communiquer avec <strong>l’assistance technique.</strong></p>                       
                          <hr />
                          <br />
                          
                          <h4>Connexion</h4>
                          <img src={mzNav5} />
                          <p>La fenêtre de connexion apparaîtra dès que vous aurez la dernière version du gestionnaire des logiciels COMMB.</p>   
                          <p>Vous trouverez votre nom d’utilisateur et votre mot de passe dans le courriel de bienvenue aux logiciels COMMB envoyé à votre adresse courriel. Si vous n’avez pas reçu ce courriel, veuillez communiquer avec  <a hre="mailto:support@commb.wpengine.com">support@commb.wpengine.com</a>, afin de créer votre compte.</p>                       
                          <ul>
                            <li>1. Entrez votre nom d’utilisateur du NavigateurCOMMBMD et du Rapport de données COMMB. Il s’agit de votre <strong>adresse courriel.</strong></li>
                            <li>2. Entrez votre mot de passe du NavigateurCOMMBMD et du Rapport de données COMMB dans la case appropriée.</li>
                            <li>3. Si vous préférez ne pas avoir à vous souvenir de votre mot de passe, cliquez dans la boîte <strong>« Enregistrer le mot de passe ».</strong> Évitez cependant de l’enregistrer si votre ordinateur possède plusieurs utilisateurs.</li>
                            <li>4. Cliquez sur <strong>« OK »</strong> pour ouvrir votre session du NavigateurCOMMBMD** et/ou du Rapport de données COMMB.</li>
                          </ul>
                          <p>
                            **Seulement certains membres de COMMB propriétaires d’entreprises d’affichage peuvent accéder au NavigateurCOMMBMD.
                          </p>
                          <p>
                            Le NavigateurCOMMBMD ou le Rapport de données COMMB tentera d’ouvrir une session sur le serveur de COMMB. Si cette opération échoue, il est possible que votre connexion Internet soit défectueuse ou que les paramètres du réseau de votre entreprise ne vous permettent pas d’établir ce lien. Le cas échéant, communiquez avec <strong>l’administrateur de votre réseau.</strong>
                          </p>
                          <hr />
                          <br />
                          <p>
                            <strong>Remarque:</strong> Il est essentiel au bon fonctionnement du NavigateurCOMMBMD et du Rapport de données COMMB que cette connexion s’effectue au moyen de vos identifiants privés. Veuillez donc <strong> éviter de les partager avec qui que ce soit.</strong> Les deux logiciels <strong> enregistrent vos fichiers et paramètres personnels</strong> dans votre compte en ligne <strong>– quiconque connaît votre mot de passe peut y accéder.</strong>
                          </p>
                          <p>
                            Si vous oubliez ou perdez votre mot de passe, entrez votre adresse courriel et cliquez sur le bouton <strong>« Mot de passe oublié ».</strong> Le système vous en créera un nouveau et l’enverra à l’adresse courriel associée à votre compte. Trouvez votre nouveau mot de passe et entrez-le dans la case appropriée de la fenêtre de connexion.
                          </p>
                          <a className="closeBtnModal" onClick={closeModalSeven}>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17.688"
                            height="17.688"
                            viewBox="0 0 17.688 17.688"
                          >
                            <path
                              id="close-icon"
                              d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                              transform="translate(-7.5 -7.5)"
                              fill="#fff"
                            ></path>
                          </svg>
                        </a>
                        </Modal>
                      </ul>
                      <h3>Autres</h3>
                      <p>Indications de déploiement pour les administrateurs de réseau</p>
                      <p>Vous n’avez pas de droits d’administration ou de service informatique? Utilisez <a href="http://www.rja.net/comb/COMBDataSuiteSetup.exe">COMMBDataSuiteSetup.exe</a> à la place.</p>
                      <a className="closeBtnModal" onClick={closeModal}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                      </Modal>                    
                    <hr />
                    <h3>Comment utiliser le logiciel</h3>
                    <ul>
                      <li>Les didacticiels (présentent chaque sujet sous forme abrégée.)</li>
                      
                      <ul>
                <li><Link to={'/fr/mz-navigator-rf/mz-nav-video-tutorials/'}>En format vidéo</Link></li>
                <li><Link to={'/fr/mz-navigator-rf/mz-nav-print-tutorials/'}>Sous forme imprimée (en anglais seulement)</Link></li>
                      </ul>
                      <li>
                        <a onClick={openModalFive}>Une série de trucs et astuces (pour des idées qui vous aideront à tirer le maximum parti du logiciel)</a>
                      </li>
                      <Modal
                        isOpen={modalIsOpenFive}
                        onRequestClose={closeModalFive}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2>NavigateurCOMMB</h2>
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-1-Naviguez-En-Expert.pdf">
                        <h3>Trucs et Astuces #1</h3>
                        <p>Naviguez En Expert</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-2-Planifier-mieux-et-plus-rapidement.pdf">
                        <h3>Trucs et Astuces #2</h3>
                        <p>Planifier mieux et plus rapidement</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-3-Planifier-plus-efficacement-et-plus-rapidement.pdf">
                        <h3>Trucs et Astuces #3</h3>
                        <p>Planifier plus efficacement et plus rapidement</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-astuce-4-Un-pas-dans-la-bonne-direction.pdf">
                        <h3>Trucs et Astuces #4</h3>
                        <p>Un pas dans la bonne direction</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-5-Maximisez-les-ressources-de-planification.pdf">
                        <h3>Trucs et Astuces #5</h3>
                        <p>Maximisez les ressources de planification</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-6-Faites-vos-Plans-comme-vous-voulez.pdf">
                        <h3>Trucs et Astuces #6</h3>
                        <p>Faites vos Plans comme vous voulez</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-7-Planifier-par-vagues-facilement.pdf">
                        <h3>Trucs et Astuces #7</h3>
                        <p>Planifier par vagues facilement</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-8-Présentez-ses-résultats-sous-forme-graphique.pdf">
                        <h3>Trucs et Astuces #8</h3>
                        <p>Présentez ses résultats sous forme graphique</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank"  href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-9-Sauvegardez-vos-plans-comme-vous-voulez.pdf">
                        <h3>Trucs et Astuces #9</h3>
                        <p>Sauvegardez vos plans comme vous voulez</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Trucs-et-Astuces-10-Affichez-des-valeurs-spécifiques-pour-les-faces-d’affichage-de-votre-campagne.pdf">
                        <h3>Trucs et Astuces #10</h3>
                        <p>Affichez des valeurs spécifiques pour les faces d’affichage de votre campagne</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-11-Consultez-des-donn%C3%A9es-pour-diverses-p%C3%A9riodes-gr%C3%A2ce-au-bouton-Change-Wave.pdf">
                        <h3>Trucs et Astuces #11</h3>
                        <p>Consultez des données pour diverses périodes, grâce au bouton</p>
                      </a>
                      <hr />
                      <br />
                      <a target="_blank" href="https://commb.ca/truc-et-astuce/Truc-et-Astuce-12-Partagez-un-rapport-avec-un-collègue.pdf">
                        <h3>Trucs et Astuces #12</h3>
                        <p>Partagez un rapport avec un collégue</p>
                      </a>
                      <hr />
                      <br />       
                      <a className="closeBtnModal" onClick={closeModal}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="17.688"
                        height="17.688"
                        viewBox="0 0 17.688 17.688"
                      >
                        <path
                          id="close-icon"
                          d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                          transform="translate(-7.5 -7.5)"
                          fill="#fff"
                        ></path>
                      </svg>
                    </a>
                    </Modal>
                      <li>
                        <a onClick={openModalTwo}>FAQ</a>
                      </li>
                       <Modal
                        isOpen={modalIsOpenTwo}
                        onRequestClose={closeModalTwo}
                        bodyOpenClassName={`modalOpen BookCOMMBModal MemberZoneModal`}
                    >
                      <h2> FAQ – NavigateurCOMMB</h2>
                      <h3>1. Comment puis-je accéder au logiciel NavigateurCOMMBMD ?</h3>
                      <p>Lorsque vous devenez membre de COMMB, vous pouvez obtenir votre nom d’utilisateur/mot de passe en remplissant le formulaire en ligne. Des droits d’administration sont requis pour installer le logiciel ; veuillez contacter votre service informatique pour qu’il vous aide à l’installer.</p>
                      <hr />
                      <br />
                      <h3>2. Comment puis-je savoir ce que signifient certains termes/données utilisés dans le NavigateurCOMMBMD ?</h3>
                      <p>Une fois dans l’application, vous pouvez accéder au document « définitions » sous l’onglet « Help ».</p>
                      <hr />
                      <br />
                      <h3>3. A quelle fréquence le NavigateurCOMMBMD est-il mis à jour ?</h3>
                      <p>Deux fois par an (janvier et juillet). Une mise à jour exceptionnelle peut être faite sur demande d’une entreprise d’affichage lorsqu’elle a un changement important dans son offre de produits et qu’elle souhaite le faire apparaître immédiatement.</p>
                      <hr />
                      <br />
                      <h3>4. Pourquoi certaines entreprises d’affichage extérieur membres de COMMB ne sont-elles pas incluses dans le NavigateurCOMMBMD ?</h3>
                      <p>Les estimations de portée/fréquence sont établies en combinant les données sur les produits d’affichage et les données de ciblage du rapport Numeris-RAE. Ceci signifie donc que les afficheurs membres de COMMB doivent aussi être membres de Numeris et détenir un abonnement à l’enquête sur les habitudes des consommateurs NLogic-RAE s’il veulent que leurs données soient rapportées dans le NavigateurCOMMBMD.</p>
                      <p>Les agences membres de COMMB doivent être membres de Numeris elles aussi pour avoir accès au logiciel.</p>
                      <hr />
                      <br />
                      <h3>5. Les produits d’affichage numérique extérieur sont-ils inclus dans le NavigateurCOMMBMD ?</h3>
                      <p>Oui ils le sont. Veuillez noter que les calculs de portée/fréquence utilisent des données de circulation portant sur les faces d’affichage publicitaire et non sur les emplacements.</p>
                      <hr />
                      <br />
                      <h3>6. Combien de marchés sont inclus dans le NavigateurCOMMBMD ?</h3>
                      <p>45 dont Toronto, Montréal, Vancouver, Calgary, Edmonton, Ottawa, London, Québec, Halifax, Winnipeg et Régina.</p>
                      <hr />
                      <br />
                      <h3>7. Le NavigateurCOMMBMD est-il inclus avec les frais d’adhésion ?</h3>
                      <p>Oui. Il n’y a pas de frais supplémentaires pour accéder au NavigateurCOMMBMD, sauf si votre compagnie n’est pas membre de Numeris.</p>
                      <hr />
                      <br />
                      <h3>8. Y a-t-il des frais de licence pour les logiciels NavigateurCOMMBMD ou le Rapport de données COMMB ?</h3>
                      <p>Il n’y a aucuns frais pour obtenir plusieurs licences pour nos logiciels. Ainsi, tous les utilisateurs d’une même entreprise peuvent disposer de leur propre compte sans frais supplémentaires.</p>
                      <hr />
                      <br />
                      <h3>9. Pourquoi le NavigateurCOMMBMD ne comporte-t-il pas de carte de tarifs ?</h3>
                      <p>
                      Les prix basés sur les conditions du marché peuvent varier considérablement au cours d’une année, nous n’incluons donc pas de cartes de tarifs afin d’éviter de fournir à nos clients des informations erronées. Pour obtenir les coûts, veuillez communiquer avec l’entreprise d’affichage concernée.
                      </p>
                      <hr />
                      <br />
                      <h3>10. Est-il possible d’utiliser le NavigateurCOMMBMD (ou le Rapport de données COMMB) sur mon Mac ?</h3>
                      <p>Le logiciel n’est pas compatible avec Mac, cependant vous pouvez examiner certaines solutions de rechange avec votre service informatique:</p>
                      <ul>
                      <li>Apple offre le logiciel Boot Camp, permettant d’installer Windows sur Macs et de démarrer avec l’un ou l’autre système.</li>
                      <li>La méthode la plus simple, cependant, est l’utilisation d’un logiciel d’émulation d’ordinateur, comme « Parallels », qui permet d’exécuter Windows (ou une seule application Windows) à l’intérieur du système OS X.</li>
                      </ul>                
                      <hr />
                      <br />
                      <h3>11. Pourquoi les abribus font-ils partie la catégorie du mobilier urbain et non rapportés séparément ?</h3>
                      <p>Dans le NavigateurCOMMBMD, on regroupe les produits ayant des dimensions identiques. Ainsi, tous les produits de mobilier urbain (tels les abribus, affiches au niveau de la rue, médiacolonnes, affiches de rue) de 6 pi. X 4 pi. sont rassemblés sous le même nom de produit.</p>
                      <a className="closeBtnModal" onClick={closeModalFive}>
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17.688"
                          height="17.688"
                          viewBox="0 0 17.688 17.688"
                        >
                          <path
                            id="close-icon"
                            d="M25.188,9.281,23.407,7.5l-7.063,7.063L9.281,7.5,7.5,9.281l7.063,7.063L7.5,23.407l1.781,1.781,7.063-7.063,7.063,7.063,1.781-1.781-7.063-7.063Z"
                            transform="translate(-7.5 -7.5)"
                            fill="#fff"
                          ></path>
                        </svg>
                      </a>
                    </Modal>
                    </ul>
                    <br />
                    <h3>Documents de Référence</h3>
                    <ul>
                      <li><a target="_blank" href="https://commb.ca/cds-documents/Termes_et_definitions_NavigateurCOMMB.pdf">Termes et définitions</a></li>
                      <li><a target="_blank" href="https://commb.ca/Fiches-Techniques-NavigateurCOMMB.pdf"> Fiches Technique pour NavigateurCOMMBMD</a></li>
                      <li><a target="_blank" href="https://commb.ca/RTS_Technical_Details_Fall_2021.pdf">RAE Détails techniques automne 2021 (en anglais)</a></li>
                      <li><a target="_blank" href="https://commb.ca/2021_Fall_RTS-Codebook-External-English.pdf">RTS Codebook automne 2021 (en anglais)</a></li>
                    </ul>
                    <br />
                    <h3>Soutien Demandes</h3>
                    <ul>
                      <li>Liées à technique ou au logiciel : <a href="mailto:support@commb.wpengine.com">support@commb.wpengine.com</a></li>
                      <li>Liées à l’installation ou à l’accès : <a href="mailto:install@commb.wpengine.com">install@commb.wpengine.com</a></li>
                    </ul> 
                </div>
            </Layout>
        </div>
    )
}

export default MembersZone